/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://gkkxdqfqevd3li6zehmaa5wqdu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "virtualEventInterface",
            "endpoint": "https://jdga9x6gq3.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "salesforceauth",
            "endpoint": "https://lfwth8h0th.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "authendpoint",
            "endpoint": "https://ub98ur6d36.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "vonageServer",
            "endpoint": "https://2ztiwic8te.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sdmPubNub",
            "endpoint": "https://9dfronde5c.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "mobile",
            "endpoint": "https://hr498888ig.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "webinarInteraction",
            "endpoint": "https://ek9razjbc1.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "drupalProxy",
            "endpoint": "https://ygq47po8c2.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sdmVirtualEventModeratorApi",
            "endpoint": "https://ac5v097bxb.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sdmBrSettingsApi",
            "endpoint": "https://t4a7qwbc59.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sdmStripeApi",
            "endpoint": "https://ibv5xrxjp5.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sdmAgendaApi",
            "endpoint": "https://g2e2a22xpe.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sdmAppointmentApi",
            "endpoint": "https://9a8jfsdlm4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sdmAzureApi",
            "endpoint": "https://l0u0imrq6c.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
